import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { RichTextModel } from '@model/richTextModel';
import { transformTitleId } from '@utils/functions/utils';

const Grid = dynamic(() => import('@mui/material/Grid'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const RichText = dynamic(() => import('@organisms/RichTextParagraph/RichText'));

const RichTextParagraph = ({ fieldParagraph }: FieldParagraphProps) => {
  const data = useMemo(() => {
    const newData: RichTextModel = {
      text: fieldParagraph.field_description?.processed || '',
      anchorData: fieldParagraph.behavior_settings?.style_options?.toc?.label || '',
    };

    return newData;
  }, [fieldParagraph]);

  if (!data?.text) return null;

  const { text } = data;

  return (
    <GridContainer
      anchor-data={transformTitleId(data.anchorData || '')}
      sx={{ py: { xs: theme.spacing(80), lg: theme.spacing(104) } }}
    >
      <Row>
        <Grid item xs={0} lg={2} />
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            '& :is(h2,h3,h4,h5,h6):first-of-type': {
              mt: 0,
            },
          }}
        >
          {text && <RichText text={text} />}
        </Grid>
      </Row>
    </GridContainer>
  );
};

export default RichTextParagraph;
